:root {
  --primary-color: #fc6633;
  --dark-color: #105e7a;
  --light-color: #e0e0e0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI';
  font-size: 1rem;
  line-height: 1.8;
}

/* GENERAL LAYOUT FOR WEBSITE 
  profile bar
  -----------
  nav bar
  -----------
  content
  -----------
*/
.main-grid-layout {
  display: grid;
  grid-template-rows: 70px 70px auto;
  height: 100vh;
}

.main-grid-layout div[class*="grid-layout"] {
  grid-row: 3;
  overflow: auto;
}

/*  NAVBAR is split into two columns
  left for a possible title, 
  right for nav buttons
*/
.navbar {
  grid-row: 2 / 3;
  width: 100%;

  display: grid;
  grid-template-columns: auto auto;

  background: var(--dark-color);
  position: fixed;
  top: 70px;
  z-index: 1;
}

/* rules for ul elements within navbar */
.navbar ul {
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 2rem;
  height: 70px;
  color: white;
  list-style-type: none;
}

/* rules for anchor elements within navbar */
.navbar a {
  color: white;
  padding: .45rem;
  margin: 0 .25rem;
  text-decoration: none;
  opacity: 0.9;
  font-size: larger;
}

/* change color on hover over anchor elements within navbar */
.navbar a:hover {
  color: rgb(127, 178, 245);
}

/* HOME PAGE LAYOUT */
.landing-grid-layout {
  display: grid;
  grid-template-rows: auto auto 300px;
}

/* LANDING CONTENT*/
.landing {
  grid-row: 1 / 2;
  background-color: white;
  text-align: center;
}

/* SAMPLE/AD CONTENT PANES */
.sample-pane {
  display: grid;
  grid-template-rows: auto auto;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  background-color: white;
  display: grid;
  margin: 50px 0px 50px 0px;
}

/* FOOTER/CONTACT US */
footer {
  grid-row: 3 / 4;
  background-color: var(--primary-color);
  color: white;
  padding: 50px;
}

/* assign these rules to all children of footer element */
footer > * {
  opacity: 0.9;
}

/*  BUTTON  */
.btn {
  display: inline-block;
  background: var(--dark-color);
  color: white;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 1rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  text-decoration: none;
  opacity: .82;
  border-radius: 2.5px;
}


/* TEXT */
.large {
  color: #20374e;
  font-size: x-large;
  align-self: center;
}

.smaller-text {
  color: #20374e;
  font-size: smaller;
}

p {
  color: #20374e;
  font-size: smaller;
}

label {
  color: #20374e;
  font-size: xx-small;
  text-align: left;
}

h3 {
  color:#20374e;
  text-align: left;
  margin-top: 3px;
}

/*  FORMS  */
.form {
    background-color: white;
    width: auto;
    height: auto;
    padding: 55px 55px 70px 55px;
    font-weight: bolder;
    font-size: normal;  
    display: block;
    justify-content: center;
    text-align: center;
}

input[type='date'] {
  font-family: 'arial';
  width: 100%;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form input[type='number'],
.form input[type='tel'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type='submit'],
button {
  font: inherit;
  background-color: var(--dark-color);
  color: white;
  font-weight: bolder;
  border: thin;
  margin-top: 15px;
  width: 45%;
  border-radius: 35px;
  outline: none;
}

input {
  width: 75%;
  height: 35px;
}

input[type=submit] {
    background-color: var(--dark-color);
    color: white;
    font-weight: bolder;
    border: thin;
    margin-top: 15px;
    width: 45%;
    border-radius: 35px;
}

/*  LOGIN  */
.button-holder {
    text-align: center;
}

.create-account {
    text-align: center;
    font-size: small;
    font-weight: lighter;
}

.forgot-password{
    text-align: center;
    font-size: small;
    font-weight: lighter;
}

label {
    display:block;
    font-weight: bold;
    font-size: larger;
}