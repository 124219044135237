.assessment-layout {
    display: grid;
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    margin: 20px 200px 20px 200px;

    justify-self: center;
    justify-items: center;
    align-content: center;
}

.questionNumber {
    grid-row: 1/2;
    font-size: 50px;
    text-align: center;
    color: black;
    display: grid;
    justify-self: start;
}

.question {
    grid-row: 2/3;
    font-size: 45px;
    padding-bottom: 25px;
    font-weight: 500;
    justify-self: start;
}

.choicesForm {
    display: grid;
    grid-row: 3/4;
    justify-items: center;
    min-width: 500px;
    max-width: 1000px;
}
.choiceRow {
    display: grid;
    grid-template-columns: 75px minmax(500px, 1000px);
    min-height: 75px;
    max-height: 80px;
    align-items: center;
}
.choiceRow input {
    grid-column: 1/2;
    min-width: 75px;
    cursor: pointer;
    outline: none;
}
.choiceRow input:disabled {
    cursor: default;
}
.choiceRow label {
    grid-column: 2/3;
}
.choiceRow .labelCorrect {
    grid-column: 2/3;
    padding-right: 10px;
    color: green;
    animation: fade 0.3s;
}
.choiceRow .labelWrong {
    grid-column: 2/3;
    padding-right: 10px;
    color: red;
    animation: shake 0.3s;
}

.quizBtn {
    width: 500px;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
}
.quizBtn:hover {
    transform: scale(1.01);
}
.quizBtn:disabled {
    opacity: 0.5;
    transform: none;
    cursor: default;
}

.explanation b {
    text-align: left;
    font-weight: 500;
}
.explanation p {
    text-align: center;
    white-space: pre-line;
    text-indent: 20px;
}
.explanation {
    grid-row: 4/5;
    font-size: x-large;
    margin-top: 50px;
    padding: 25px;
    background-color: whitesmoke;
    animation: fade 0.3s;
    margin-bottom: 10px;
    max-width: 1000px;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

@keyframes reverseShake {
    0% {color: black;}
    100% {color: black;}
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}