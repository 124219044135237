.presentor-grid-layout {
    display: grid;
    grid-template-rows: minmax(0,1fr) 150px;
    height: 100%;
}

.slide-content {
    display: grid;
    border: 1px solid rgba(0,0,0,0.2);
    margin: 10px;
    overflow: auto;
}

.presentor-controls {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(150px,1fr) minmax(250px,1fr);
    grid-row: 2/3;
    justify-items: center;
}
.prev-btn {
    display: grid;
    grid-column: 1/2;
    grid-template-columns: 150px 125px;
    align-items: center;
    user-select: none;
}
.prev-btn .leftArrow {
    display: grid;
    grid-column: 1/2;
    overflow: visible;
    transition: 0.3s;
    animation: ease-in-out;
    cursor: pointer;
}
.prev-btn b {
    display: grid;
    grid-column: 2/3;
    font-size: x-large;
    color: black;
    background-color: white;
    justify-self: center;
}
.prev-btn .leftArrow:hover {
    transform: scale(1.1);
}

.slide-number {
    display: grid;
    grid-column: 2/3;
    align-items: center;
    font-size: xx-large;
}

.next-btn {
    display: grid;
    grid-column: 3/4;
    grid-template-columns: 100px 150px;
    align-items: center;
    user-select: none;
}
.next-btn b {
    display: grid;
    grid-column: 1/2;
    font-size: x-large;
    color: black;
    background-color: white;
    justify-self: center;
}
.next-btn .rightArrow {
    display: grid;
    grid-column: 2/3;
    overflow: visible;
    transition: 0.3s;
    animation: ease-in-out;
    cursor: pointer;
}
.next-btn button:hover, .next-btn .rightArrow:hover {
    transform: scale(1.1);
}

.next-btn button:hover,.prev-btn button:hover {
    color: var(--primary-color);
}