.finishButton {
    font-size: x-large;
    background-color: var(--primary-color);
    cursor: pointer;
    transition: 0.3s;
    animation: ease-in-out;
    width: 800px;
}

.finishButton:hover {
    transform: scale(1.1);
}