.profile-grid-layout {
    display: grid;
    grid-template-rows: auto auto auto;
}

.section-header {
    background-color: var(--dark-color);
    color: white;
    font-weight: 500;
    font-size: large;
    height: 80px;
    margin-bottom: 15px;
    padding: 25px 0px 0px 15px;
}

.section-fields {
    display: grid;
    align-content: flex-start;
}

.top-row-layout {
    display: grid;
    grid-template-columns: auto auto;
    grid-row: 1/2;
    margin: 25px 25px 15px 25px;
}

.personal-info-layout {
    display: grid;
    grid-column: 1/2;
    margin-right: 15px;
    color: #105e7a;
    background-color:rgba(16, 94, 122, 0.2);
    grid-template-rows: min-content auto;
}

.account-info-layout {
    display: grid;
    grid-column: 2/3;
    background-color:rgba(16, 94, 122, 0.2);
    grid-template-rows: min-content auto;
}

.org-layout {
    display: grid;
    grid-row: 2/3;
    margin: 0px 25px 0px 25px;
    background-color:rgba(16, 94, 122, 0.2);
    grid-template-rows: min-content auto;
}

.resetBtn {
    justify-self: center;
    background-color: var(--dark-color);
    color: white;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    border-radius: 5px;
    width: 75%;
}

.saveChangesBtn {
    background-color: var(--primary-color);
    width: 250px;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.profile-grid-layout select {
    width: 100%;
    height: 35px;
}

.deleteBtn {
    justify-self: center;
    background-color: var(--primary-color);
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 75%;
}

.deleteBtn:disabled {
    justify-self: center;
    background-color: var(--primary-color);
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    border-radius: 5px;
    width: 75%; 
    opacity: 0.5;
    cursor: not-allowed;
}