/* PROFILE BAR */
.profile-bar {
    grid-row: 1 / 2;
    display: grid;
    justify-content: end;
    padding: 5px;
    width: 100%;
    height: 70px;
    background-color: black;
    top: 0px;
    position: fixed;
    padding-right: 45px;
    z-index: 2;
  }
  
  /* rules for ul elements within profile-bar */
  .profile-bar ul {
    display: grid;
    list-style-type: none;
    grid-template-columns: auto auto;
  }
  .profile-bar .ul-logged-in {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 10px;
  }
  .profile-bar li {
    align-self: center;
  }

  .profile-menu {
    position: fixed;
    top: 70px;
    right: 20px;
    background-color: var(--light-color);
    border: 1px solid black;
    border-radius: 5px;
    z-index: 3;
  }
  .profile-menu>ul>li>a {
    color: black;
  }
  
  /* rules for anchor elements within profile-bar */
  .profile-bar a {
    color: white;
    padding: .45rem;
    margin: 0 .25rem;
    text-decoration: none;
    opacity: 0.9;
    font-size: larger;
  }
  
  /*change color for hover over elements*/
  .profile-bar a:hover {
    color: rgb(127, 178, 245);
  }

.pBarLogoutBtn {
    background-color: rgba(255,255,255,0);
    color: red;
    padding: 0px 30px 0px 30px;
    margin: 0px 10px 0px 10px;
    width: auto;
    font-size: larger;
    font-weight: bold;
    cursor: pointer;
}

.pBarLogoutBtn:hover {
    color: rgb(255, 101, 101);
}

.frontPageLogoutBtn { 
    background-color: black;
    cursor: pointer;
    color: rgb(255, 176, 176);
    margin: 0;
    width: 100%;
}
.frontPageLogoutBtn:hover {
    color: rgb(255, 101, 101);
}

.profile-bar #profile-icon {
    border-radius: 30px;
    transform: scaleX(0.95);
    border: 1px solid white;
    animation: ease-in-out;
    transition: .3s;
}

.profile-bar #profile-icon:hover {
    transform: scale(1.15);
}