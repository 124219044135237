.user-statistics-grid-layout {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 25px;
    column-gap: 15px;
}

.user-statistics-grid-layout h2 {
    font-weight: 400;
}

.usersListDashboard {
    display: grid;
    grid-column: 1/2;
    column-gap: 1px;

    grid-template-columns: 200px auto;
    grid-template-rows: min-content minmax(0, 1fr);

    min-height: min-content;
    background-color: rgba(0,0,0,0.05);
}
.usersList {
    display: grid;
    grid-row: 2/3;
    grid-column: 1/3;

    align-content: start;
    overflow-y: auto;
    border-radius: 3px;

    font-weight: 500;
}

.userRow {
    display: grid;
    grid-template-columns: 200px 1fr 0.1fr;
    margin: 2px 0px 2px 0px;
}
.userRow:hover {
    background-color: lightskyblue;
}
.userRowSelected {
    display: grid;
    grid-template-columns: 200px 1fr 0.1fr;
    margin: 2px 0px 2px 0px;
    height: min-content;
    background-color: lightskyblue;
}

.userNames {
    display: grid;
    grid-column: 1/2;
    text-align: center;
}

.totalProgressBar {
    display: grid;
    grid-column: 2/3;

    margin: 2px 0px 2px 0px;
    border-radius: 3px;

    background-color: white;

    height: 30px;
    width: auto;
}
.totalProgressBar span {
    display: grid;
    background-color: lime;
    border-radius: 3px;
}

.userDisplayDashboard {
    display: grid;
    grid-column: 2/3;
    grid-template-rows: min-content minmax(0, 1fr);
    min-height: min-content;
}

.userModuleProgress {
    display: grid;
    grid-row: 2/3;
    grid-template-rows: min-content auto;

    border-radius: 3px;

    background-color: rgba(0,0,0,0.05);
}
.selectedUserInfo {
    display: grid;
    grid-row: 1/2;
    grid-template-rows: min-content min-content;

    border-radius: 3px; 
    border-bottom: 1px solid white;
    padding: 15px; 

    font-size: 25px;
    font-weight: 500;
}
.selectedUserProgress {
    display: grid;
    grid-row: 2/3;
    row-gap: 25px;

    overflow-y: scroll;
    align-content: start;

    border-radius: 3px; 
    border-width: 0px 1px 1px 1px; 
    padding: 15px;
}

.moduleProgress {
    display: grid;
    grid-template-rows: min-content min-content;

    height: min-content;

    font-weight: 500;
    font-size: x-large;
}
.moduleHeader {
    display: grid;
    grid-row: 1/2;
}
.moduleProgressRow
{
    display: grid;
    grid-row: 2/3;
    grid-template-columns: 1fr 0.1fr;
}
.moduleProgressBar {
    display: grid;
    grid-column: 1/2;
    
    border-radius: 3px;
    width: auto;

    background-color: white;
}
.moduleProgressBar span {
    display: grid;
    border-radius: 3px;
    background-color: lime;
}