.aboutus-grid-layout
{
    justify-self: center;
    padding: 100px;
    max-width: 1200px;
    font-size: larger;
}

.aboutus-text
{
    background-color: whitesmoke;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
}

.paragraphtext
{
    font-weight: 600;
    color: black;
}
