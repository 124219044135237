.voiceoverLayout {
    display: grid;
    grid-template-rows: auto min-content;
    background-position: center;
    background-repeat: no-repeat;
    
    justify-items: center;
    align-items: flex-end;
}

audio {
    outline: none;
}

.playPauseSvg {
    overflow: visible;
    cursor: pointer;
    display: grid;
    grid-row: '1/2';
    margin-bottom: 25px;
    filter: drop-shadow(2px 2px rgba(0,0,0,0.8));
    transition: .3s;
    animation: ease-in-out;
}

.playPauseSvg:hover {
    transform: scale(1.1)
}