.contactForm-grid-layout {
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.3fr ;
    grid-template-rows: auto;
    grid-template-areas: "lside dashmain rside" ;
}

.contactForm-grid-layout .form{
    background-color: white;
    grid-area: dashmain;
}

.backColorOrange{
    background: #fc6633 !important;
}

.leftSide {
    grid-area: lside;
    background-color: #fc6633;
}

.rightSide {
    grid-area: rside;
    background-color: #fc6633;
}