.image-layout {
    display: grid;
    grid-template-rows: auto auto;
    align-content: center;
    justify-content: center;
}

.image-layout button {
    width: 200px;
    border-radius: 5px;
    font-size: x-large;
    cursor: pointer;
    background-color: var(--primary-color);
    opacity: 0.9;
}
.image-layout button:hover {
    transform: scale(1.01)
}
.image-layout button:disabled {
    display: none;
}

.hide-text-explanation {
    display: none;
}
.text-explanation {
    animation: fade 0.3s;
    max-width: 1000px;
    background-color: whitesmoke;
    padding: 25px;
    margin: 0px 50px 100px 50px;
}
.text-explanation b {
    font-size:  x-large;
    font-weight: 500;
}
.text-explanation p {
    font-size: larger;
    text-indent: 20px;
    white-space: pre-line;
}

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}