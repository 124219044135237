.cardContainer {
    margin: 50px;
    background-color: white;
    min-width: 300px;
    width: 300px;
    height: 490px;
    border-radius: 15px;
    border: 15px solid var(--primary-color);
    box-shadow: 0px 0px 15px -5px grey;
    transition: 0.3s;
    animation: ease-in-out;
    padding: 10px;
    cursor: pointer;
}
.cardContainerLocked {
    opacity: 0.6;
    margin: 50px;
    background-color: white;
    min-width: 300px;
    width: 300px;
    height: 490px;
    border-radius: 15px;
    border: 15px solid rgba(252, 102, 51, 0.5);
    box-shadow: 0px 0px 15px -5px grey;
    transition: 0.3s;
    animation: ease-in-out;
    padding: 10px;
}

.cardContainer:hover {
    transform: scale(1.1);
}

.cardTitle h3 {
    padding-bottom: 15px;
}

.cardTitle {
    padding: 1rem;
    text-align: center;
    color:#20374e;
    border-bottom: 1px solid #20374e;
}

.cardDescription {
    padding: 1rem;
    color: darkslategrey;
    display: flex;
    justify-content: center;
}

.progressBar {
    color: #20374e;
    display: flex;
    border: 1px solid #20374e;
    border-radius: 10px;
    margin: 0px 20px 0px 20px;
    height: 35px;
    text-align: center;
}
.progressBar span {
    background-color: lime;
    border-radius: 10px;
}

.card-btn {
    display: flex;
    justify-content: center;
}

.card-btn button {
    background-color: white;
    width: 100%;
    font-size: large;
    color: black;
    padding-top: 20px;
    cursor: pointer;
}

.cardContainer .card-btn button:hover {
    color: #fc6633;
}